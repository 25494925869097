import { Injectable, inject } from '@angular/core';

import { AuthService } from '@/app/backend/auth.service';
import { RouterService } from '@/shared/services/router.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  readonly #routerService = inject(RouterService);
  readonly #auth = inject(AuthService);

  canActivate() {
    if (!!this.#auth.token && !!this.#auth.user) {
      return true;
    }
    this.#routerService.navigateToLogin();
    return false;
  }
}
